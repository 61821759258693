import axios from "axios"

const PROD = true
const API_URL = PROD ? "https://my-app-wrh7z.ondigitalocean.app":"http://localhost:5432"

const login = async (username, password) => {
  if (username != "cameron" && username != "mreza" && username != "justinyee") {
    return undefined
  }
  const data = {
    username,
    password,
  }
  const res = axios.post(`${API_URL}/signin`, data).catch((e) => {
    console.log("Error in login", e)
    return undefined
  })
  return res
}

const getAllEvents = async (token) => {
  const data = {}
  const config = {
    headers: {
      Authorization: token,
    },
  }
  const res = await axios
    .post(`${API_URL}/getAllEvents`, data, config)
    .catch((e) => {
      console.log("Error in getAllEvents", e)
      return undefined
    })
  return res
}

const getEvent = async (token, query) => {
  const data = {}
  const config = {
    headers: {
      Authorization: token,
    },

    params: {
      title: query,
    },
  }
  const res = await axios
    .post(`${API_URL}/searchEvent`, data, config)
    .catch((e) => {
      console.log("Error in searchEvent", e)
      return undefined
    })
  return res
}

const getAllAccounts = async (token) => {
  const data = {}
  const config = {
    headers: {
      Authorization: token,
    },
  }

  const res = await axios
    .post(`${API_URL}/getAllAccounts`, data, config)
    .catch((e) => {
      console.log("Error in getAllAccounts", e)
      return undefined
    })
  return res
}

const getAccountByID = async (token, id) => {
  const data = {}
  const config = {
    headers: {
      Authorization: token,
    },
    params: {
      id,
    },
  }
  const res = await axios
    .post(`${API_URL}/getAccountByID`, data, config)
    .catch((e) => {
      console.log("Error in getAccountByID", e)
      return undefined
    })
  return res
}

// const getPopularHosts = async (token) => {
//   const data = {}
//   const config = {
//     headers: {
//       Authorizaton: token,
//     },
//   }
// }

const getAccount = async (token, name) => {
  const data = {}
  const config = {
    headers: {
      Authorization: token,
    },

    params: {
      name,
    },
  }

  const res = await axios
    .post(`${API_URL}/searchAccount`, data, config)
    .catch((e) => {
      console.log("Error in searchAccount", e)
      return undefined
    })
  return res
}

const updateEvent = async (token, id, title, description, time) => {
  const data = {
    title,
    description,
    time,
    id,
  }

  const config = {
    headers: {
      Authorization: token,
    },
  }

  const res = await axios
    .post(`${API_URL}/updateEvent`, data, config)
    .catch((e) => {
      console.log("Error in updateEvent", e)
      return undefined
    })
  return res
}

const deleteEvent = async (token, id) => {
  const data = {
    id,
  }

  const config = {
    headers: {
      Authorization: token,
    },
  }

  const res = await axios
    .post(`${API_URL}/deleteEvent`, data, config)
    .catch((e) => {
      console.log("Error in deleteEvent", e)
      return undefined
    })
  return res
}

export {
  login,
  getAllEvents,
  getEvent,
  getAllAccounts,
  getAccountByID,
  getAccount,
  updateEvent,
  deleteEvent,
}

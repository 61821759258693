import React from "react"
import { Link } from "react-router-dom"
import { Container, Navbar, Nav } from "react-bootstrap"

const NavigationBar = (props) => {
  const { token } = props
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand>Eclipse Portal</Navbar.Brand>
        {token ? <Navbar.Toggle aria-controls="basic-navbar-nav" /> : null}
        <Navbar.Collapse id="basic-navbar-nav">
          {token ? (
            <Nav className="Me-auto">
              <Nav.Link
                as={Link}
                to="/home"
                style={{ textDecoration: "none", color: "black" }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/events"
                style={{ textDecoration: "none", color: "black" }}
              >
                Events
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/users/all"
                style={{ textDecoration: "none", color: "black" }}
              >
                Users
              </Nav.Link>
            </Nav>
          ) : (
            <Nav className="Me-auto"></Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavigationBar

import React from "react";
import PageLoader from "./PageLoader";

function App() {
  console.log("app refresh");
  return (
    <div className="App">
      <PageLoader />
    </div>
  );
}

export default App;

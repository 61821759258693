import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useParams } from "react-router-dom"
import ContentEditable from "./ContentEditable"
import pfp from "../assets/default_pfp.png"
import { getAccountByID } from "../DAL"

const UserView = (props) => {
  const { token } = props
  const [user, setUser] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const { id } = useParams()

  useEffect(() => {
    const getUser = async () => {
      setLoading(true)
      const res = await getAccountByID(token, id)
      if (res) {
        setUser(res.data)
        setLoading(false)
      }
    }

    getUser()
  }, [])

  if (loading) {
    return <div>Loading</div>
  }
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Container style={{ width: 500 }}>
        <Row>
          <img
            src={pfp}
            style={{ width: 100, padding: 0, borderRadius: 50 }}
            alt="user-pfp"
          />
        </Row>
        <Row>
          <Col>
            <div className="text-secondary">Name</div>
            <ContentEditable>{user.name}</ContentEditable>
          </Col>
          <Col>
            <div className="text-secondary">Email</div>
            <div className="text-muted">{user.email}</div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <div className="text-secondary">Username</div>
            <ContentEditable>{user.username}</ContentEditable>
          </Col>
          <Col>
            <div className="text-secondary">ID</div>
            <div className="text-muted">{user.id}</div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xl={12}>
            <div className="text-secondary">Bio</div>
            <ContentEditable>{user.bio}</ContentEditable>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <div className="text-secondary">Gender</div>
            <ContentEditable>{user.gender_id}</ContentEditable>
          </Col>
          <Col>
            <div className="text-secondary">Membership</div>
            <div className="text-muted">{user.organization}</div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <div className="text-secondary">Birthday</div>
            <ContentEditable>{user.birthday}</ContentEditable>
          </Col>
          <Col>
            <div className="text-secondary">Creation Date</div>
            <div className="text-muted">{user.creation_date}</div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UserView

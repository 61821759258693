import React, { useState, useEffect } from "react"
import {
  Container,
  ListGroup,
  FormControl,
  InputGroup,
  Button,
} from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import pfp from "../assets/default_pfp.png"
import { getAccount, getAllAccounts } from "../DAL"

const Users = (props) => {
  const { token } = props
  const [userQuery, setUserQuery] = useState("/all")
  const [users, setUsers] = useState([])
  const navigate = useNavigate()

  const handleQuery = (e) => {
    setUserQuery(e.target.value.trim())
  }

  const handleSearch = async (e) => {
    e.preventDefault()
    const res = await getAccount(token, userQuery)
    try {
      if (res.status === 200) {
        setUsers(res.data)
      }
    } catch (err) {
      console.log("Error in search", err)
      navigate("/logout", { replace: true })
    }
  }

  useEffect(() => {
    const getUsers = async () => {
      const res = await getAllAccounts(token)
      try {
        if (res.status === 200) {
          setUsers(res.data.accounts)
        }
      } catch (e) {
        console.log("Error getting users", e)
        navigate("/logout", { replace: true })
      }
    }

    getUsers()
  }, [])

  return (
    <Container>
      <form onSubmit={handleSearch}>
        <InputGroup className="mb-3">
          <FormControl placeholder="Search Users" onChange={handleQuery} />
          <Button type="submit">Search</Button>
        </InputGroup>
      </form>
      <ListGroup>
        {users.map((user, index) => (
          <ListGroup.Item
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            key={index}
          >
            <img
              style={{ width: 28, borderRadius: 35, marginRight: 10 }}
              src={pfp}
              alt={`${user} pfp`}
            />
            <Link
              to={`/users/profile/${user.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="text-primary" style={{ fontWeight: 600 }}>
                {user.name}
              </div>
            </Link>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  )
}

export default Users

import React from "react"
import { Link } from "react-router-dom"

const Logout = (props) => {
  return (
    <div>
      <div>You have been logged out</div>
      <Link to="/">Log back in</Link>
    </div>
  )
}

export default Logout

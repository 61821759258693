import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  FormControl,
  InputGroup,
  Button,
} from "react-bootstrap"
import EventCard from "../components/EventCard"
import { getAllEvents, deleteEvent, updateEvent } from "../DAL"
import { useNavigate } from "react-router-dom"

const Events = (props) => {
  const { token } = props
  const [eventsQuery, setEventsQuery] = useState("")
  const [events, setEvents] = useState([])
  const [displayEvents, setDisplayEvents] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const navigate = useNavigate()

  const handleQuery = (e) => {
    setEventsQuery(e.target.value.trim())
  }

  const handleSearch = async (e) => {
    e.preventDefault()
    setLoading(true)
    setDisplayEvents(
      events.filter((event) =>
        event.title.toLowerCase().includes(eventsQuery.toLowerCase())
      )
    )
    setLoading(false)
  }

  const getEvents = async () => {
    const res = await getAllEvents(token)
    try {
      if (res.status === 200) {
        console.log(res)
        setEvents(res.data)
        setDisplayEvents(res.data)
      } else {
        setError(true)
      }
    } catch (e) {
      console.log("Error getting all events", e)
      navigate("/logout", { replace: true })
    }
  }

  const handleDeleteEvent = async (id) => {
    await deleteEvent(token, id)
    setEventsQuery("")
    getEvents()
  }

  const handleUpdateEvent = async (id, title, description, time) => {
    const res = await updateEvent(token, id, title, description, time)
    try {
      if (res.status === 200) {
        setEventsQuery("")
        getEvents()
      }
    } catch (e) {
      console.log("Error updating event", e)
      navigate("/logout", { replace: true })
    }
  }

  useEffect(() => {
    getEvents()
  }, [])

  if (error) {
    return <div>Error getting events</div>
  }
  return (
    <Container>
      <form onSubmit={handleSearch}>
        <InputGroup className="mb-3">
          <FormControl placeholder="Search Events" onChange={handleQuery} />
          <Button type="submit" disabled={loading}>
            Search
          </Button>
        </InputGroup>
      </form>
      <Row>
        {displayEvents.map((event, index) => (
          <div
            className="col-sm-4"
            style={{ marginBottom: 15 }}
            key={`event-div${event.id}`}
          >
            <EventCard
              deleteEvent={handleDeleteEvent}
              updateEvent={handleUpdateEvent}
              id={event.id}
              title={event.title}
              hostID={event.host}
              description={event.description}
              time={event.time}
              key={index}
              token={token}
            />
          </div>
        ))}
      </Row>
    </Container>
  )
}

export default Events

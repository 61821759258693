import React, { useState } from 'react'
import {
    Button,
    Form
} from 'react-bootstrap'


const ContentEditable = (props) => {
    const [originalText, setOriginalText] = useState(props.children)
    const [text, setText] = useState(props.children)
    const [editing, setEditing] = useState(false)

    const handleUpdate = () => {
        const execution = props.onChange ? props.onChange() : null
        setOriginalText(text)
        setEditing(false)
    }
    const handleCancel = () => {
        setText(originalText)
        setEditing(false)
    }

    return editing ? (
        <div style={{display:'flex', flexDirection:'row'}}>
            <div style={props.textStyle ? props.textStyle : {fontWeight: 600, display:'flex', flexDirection:'row'}}>
                <Form>
                    <Form.Group>
                        <Form.Control size="md" type="text" placeholder={props.placeholder ? props.placeholder : "Enter Text"} value={text} onChange={(e) => setText(e.target.value)}/>
                    </Form.Group>
                    <div style={{marginTop:5}}>
                        <Button variant='primary' onClick={() => handleUpdate()} style={{marginRight:5}}>Done</Button>
                        <Button variant='outline-secondary' onClick={() => handleCancel()} style={{marginLeft:5}}>Cancel</Button>
                    </div>
                </Form>
            </div>
        </div>
    ) : (
        <div style={{display:'flex', flexDirection:'row'}}>
            <div style={props.textStyle ? props.textStyle : {fontWeight: 600}} onClick={() => setEditing(true)}>
                {text}
            </div>
        </div>
    )
}

export default ContentEditable
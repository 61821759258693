import React, { useState, useEffect } from "react"
import Login from "./routes/login"
import { Container, Row } from "react-bootstrap"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./routes/home"
import PrivateRoute from "./components/PrivateRoute"
import NavigationBar from "./components/Navbar"
import Events from "./routes/events"
import Users from "./routes/users"
import Logout from "./routes/logout"
import UserView from "./components/UserView"
import Cookies from "universal-cookie"
import { login } from "./DAL"

const PageLoader = () => {
  const [cookies] = useState(new Cookies())
  const [token, setToken] = useState(cookies.get("userToken"))

  const handleLogin = async (username, password) => {
    const res = await login(username, password)
    if (res) {
      setToken(res.data.token)
      return res
    } else {
      throw new Error()
    }
  }

  useEffect(() => {
    if (token) {
      cookies.set("userToken", token)
    } else {
      setToken(cookies.get("userToken"))
    }
  }, [cookies, token, setToken])

  return (
    <>
      <Router>
        <NavigationBar token={token} />
        <Container>
          <Row
            style={{
              marginTop: 100,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Routes>
              <Route
                path="home"
                element={
                  <PrivateRoute token={token} setToken={setToken}>
                    <Home token={token} />
                  </PrivateRoute>
                }
              />
              <Route
                path="events"
                element={
                  <PrivateRoute token={token} setToken={setToken}>
                    <Events token={token} />
                  </PrivateRoute>
                }
              />
              <Route
                path="users/profile/:id"
                element={
                  <PrivateRoute token={token} setToken={setToken}>
                    <UserView token={token} />
                  </PrivateRoute>
                }
              />
              <Route
                path="users/:id"
                element={
                  <PrivateRoute token={token} setToken={setToken}>
                    <Users token={token} />
                  </PrivateRoute>
                }
              />
              <Route path="logout" element={<Logout />} />
              <Route path="/" element={<Login handleLogin={handleLogin} />} />
            </Routes>
          </Row>
        </Container>
      </Router>
    </>
  )
}

export default PageLoader

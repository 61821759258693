import React, { useState, useEffect } from "react"
import {
  Card,
  Form,
  Button,
  DropdownButton,
  Dropdown,
  Container,
  Row,
  Col,
} from "react-bootstrap"
import { getAccountByID } from "../DAL"

const EventCard = (props) => {
  const {
    updateEvent,
    deleteEvent,
    id,
    title,
    description,
    time,
    hostID,
    token,
  } = props
  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [eventID, setEventID] = useState(id)
  const [eventTitle, setEventTitle] = useState(title)
  const [eventDescription, setEventDescription] = useState(description)
  const [eventTime, setEventTime] = useState(time)
  const [host, setHost] = useState("")

  const handleDeleteEvent = () => {
    deleteEvent(id)
  }

  const handleUpdateEvent = (e) => {
    e.preventDefault()
    updateEvent(eventID, eventTitle, eventDescription, eventTime)
  }

  const handleTitleChange = (e) => {
    const newTitle = e.target.value.trim()
    setEventTitle(newTitle)
  }

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value.trim()
    setEventDescription(newDescription)
  }

  useEffect(() => {
    setEventID(id)
    setEventTitle(title)
    setEventDescription(description)
    setEventTime(time)

    const getAccount = async () => {
      const res = await getAccountByID(token, hostID)
      if (res) {
        setHost(res.data.username)
      }
    }

    getAccount()
  }, [id, title, description, time, hostID, token])

  return editing ? (
    <Card style={{ width: 425 }}>
      <Card.Body>
        <Form onSubmit={handleUpdateEvent}>
          <Form.Group>
            <Form.Label>Edit Title</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              placeholder="Enter Event Title"
              value={eventTitle}
              onChange={handleTitleChange}
            />
          </Form.Group>
          <br />
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Edit Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter Event Description"
              value={eventDescription}
              onChange={handleDescriptionChange}
            />
          </Form.Group>
          <br />
          {/* <Form.Group>
            <Form.Label>Edit Event Time</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter Event Time"
              value={eventTime}
            />
          </Form.Group> */}

          <br />
          <Button variant="outline-primary" type="submit">
            Done
          </Button>
        </Form>
      </Card.Body>
      <Card.Footer className="text-muted">
        <Card.Subtitle>
          Created by {host} at{" "}
          {props.creationTime ? props.creationTime : "an unknown time"}
        </Card.Subtitle>
      </Card.Footer>
    </Card>
  ) : (
    <Card style={{ width: 425 }}>
      <Card.Body>
        <Card.Title>
          {eventID}: {eventTitle}
        </Card.Title>
        <Card.Subtitle>Description</Card.Subtitle>
        <Card.Text>{eventDescription}</Card.Text>
        <Card.Subtitle>Date of event</Card.Subtitle>
        <Card.Text>{time}</Card.Text>
        <Container style={{ padding: 0, margin: 0 }}>
          <Row xs={2} md={4} lg={6}>
            <Col sm>
              <Button onClick={() => setEditing(!editing)}>Edit</Button>
            </Col>
            <Col sm>
              <DropdownButton variant="outline-secondary" title="Other Actions">
                <Dropdown.Item onClick={handleDeleteEvent}>
                  Delete Event
                </Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
        </Container>
      </Card.Body>
      <Card.Footer className="text-muted">
        <Card.Subtitle>
          Created by {host} at{" "}
          {props.creationTime ? props.creationTime : "an unknown time"}
        </Card.Subtitle>
      </Card.Footer>
    </Card>
  )
}

export default EventCard

import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Card, Form, Button } from "react-bootstrap"
const Login = (props) => {
  const { handleLogin } = props
  const navigate = useNavigate()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const fetchLogin = async (e) => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    }

    setLoading(true)
    try {
      const res = await handleLogin(username, password)
      if (res.status === 200) {
        setError(false)
        setLoading(false)
        navigate("/home")
      }
    } catch (e) {
      console.log("Error logging in", e)
      setError(e)
      setLoading(false)
    }
  }

  return (
    <Card style={{ width: "18rem" }}>
      <Card.Body>
        <Card.Title>Log In</Card.Title>
        <Form onSubmit={fetchLogin} autoComplete="off">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Username</Form.Label>
            <Form.Control
              placeholder="Enter Username"
              onChange={handleUsernameChange}
              isInvalid={error}
            />
            <Form.Control.Feedback type="invalid">
              Something went wrong.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Password"
              onChange={handlePasswordChange}
              isInvalid={error}
            />
            <Form.Control.Feedback type="invalid">
              Something went wrong.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Button type="submit" disabled={loading}>
              Log In
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default Login

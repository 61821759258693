import React from "react"
import { Link } from "react-router-dom"
import { Card, Container, Row, Col, Nav } from "react-bootstrap"

const Home = (props) => {
  return (
    <Container>
      <Row>
        <Col>
          <Nav.Link
            as={Link}
            to="/users/all"
            style={{ textDecoration: "none" }}
          >
            <Card>
              <Card.Body>
                <Card.Title>Active Users</Card.Title>
                <Card.Subtitle>600</Card.Subtitle>
              </Card.Body>
            </Card>
          </Nav.Link>
        </Col>
        <Col>
          <Nav.Link as={Link} to="/events" style={{ textDecoration: "none" }}>
            <Card>
              <Card.Body>
                <Card.Title>New Events Today</Card.Title>
                <Card.Subtitle>50</Card.Subtitle>
              </Card.Body>
            </Card>
          </Nav.Link>
        </Col>
        <Col>
          <Nav.Link
            as={Link}
            to="/users/new"
            style={{ textDecoration: "none" }}
          >
            <Card>
              <Card.Body>
                <Card.Title>New Users</Card.Title>
                <Card.Subtitle>125</Card.Subtitle>
              </Card.Body>
            </Card>
          </Nav.Link>
        </Col>
      </Row>
    </Container>
  )
}

export default Home
